<script setup>
import Button from '@/Components/Button/Button.vue';
import LinkButton from '@/Components/Button/Link.vue';
import Icon from '@/Components/Icon.vue';
import Img from '@/Components/Img.vue';
import Filepond from '@/Components/JobOffers/Employers/Filepond.vue';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import { mapErrors } from '@/utilities';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';
import { CheckIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import Checkmark from '@i/checkmark-colourable.svg?raw';
import { useForm } from '@inertiajs/vue3';
import { useScroll } from '@vueuse/core';
import { useDebounceFn } from '@vueuse/shared';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const route = inject('route');

const props = defineProps({
    page: Object,
    plans: Object,
    plan_extras: Object,
    query: Object,
});

const companiesList = ref([]);
const searchCompaniesQuery = ref('');
const selectedCompany = ref(null);

const debouncedGetKvkCompanies = useDebounceFn(() => getKvkCompanies(), 600);

const getKvkCompanies = () => {
    axios
        .get(route('api.v0.place-job-offer.kvk-controller.index', { trade_name: searchCompaniesQuery.value }))
        .then((response) => {
            companiesList.value = response.data.data;
        });
};

const fillDetailsFromKvk = () => {
    axios
        .get(route('api.v0.place-job-offer.kvk-controller.show', { kvk_number: selectedCompany.value.kvk_number }))
        .then((response) => {
            form.company.commercial_name = response.data.name;
            form.company.kvk_number = selectedCompany.value.kvk_number;
            form.company.address =
                response.data.street_name +
                ' ' +
                response.data.street_number +
                ' ' +
                response.data.street_number_addition;
            form.company.postal_code = response.data.postcode;
            form.company.city = response.data.city;

            kvkFilled.value = true;
        });
};

const tabs = computed(() => {
    return [
        {
            step_title: t('Fill company details'),
            has_errors:
                !!Object.keys(formErrors.value?.errors.company ?? {}).length ||
                !!formErrors.value?.errors?.logo,
        },
        {
            step_title: t('Choose plan'),
            has_errors: !!formErrors.value?.errors.company?.plan_id ?? false,
        },
        {
            step_title: t('Job offer description'),
            has_errors: !!Object.keys(formErrors.value?.errors.job_offer ?? {}).length || !!formErrors.value?.errors?.atmospheric_image
        },
    ];
});

const form = useForm({
    accepted: false,
    logo: null,
    atmospheric_image: null,
    plan_extras: [],
    company: {
        commercial_name: '',
        kvk_number: '',
        address: '',
        postal_code: '',
        city: '',
        description: '',
        first_name: '',
        last_name: '',
        email: '',
        function: '',
        tel: '',
        invoice_address: '',
        // vat_number: '',
        plan_id: null,
    },
    job_offer: {
        function: '',
        department: '',
        hours: '',
        start_type: 'date',
        start_date: '',
        end_date: '',
        education: '',
        employment: '',
        description: '',
        main_activities: [],
        who_you_are: [],
        what_you_get: [],
        salary: '',
        compensations: [],
    },
});

let formErrors = ref();

let compensation = ref('');
let mainActivity = ref('');
let whoYouAre = ref('');
let whatYouGet = ref('');

let scroller = ref();
onMounted(() => (scroller.value = window));
const { y } = useScroll(scroller);

let kvkFilled = ref(false);

let tabIndex = ref(0);
let hasBeenToIndex = ref(0);

let logoPreviewError = ref(false);

let atmosphericImagePreviewError = ref(false);


function submitHandler(context, index) {

    if (index === 1) {
        if (!logoPreview.value) {
            logoPreviewError.value = true;
        } else {
            logoPreviewError.value = false;

        }

        if (!logoPreview.value) {
            return;
        }
    }

    nextStep(index);
}

function nextStep(index) {
    if (index >= hasBeenToIndex.value) hasBeenToIndex.value = index;
    slide(index);
}

let transition = ref('slide-left');

function slide(index) {
    if (index > tabIndex.value) {
        transition.value = 'slide-left';
    } else {
        transition.value = 'slide-right';
    }
    if (index === tabIndex.value) {
        document.documentElement.scrollTop = 0;
    } else if (hasBeenToIndex.value >= index) {
        setTimeout(() => (document.documentElement.scrollTop = 0), 400);
    }
    if (hasBeenToIndex.value >= index) {
        tabIndex.value = index;
    }
}

let hasBeenSuccessful = ref(false);

const sendForm = (data) => {

    form.transform((data) => ({
        ...data,
        logo: {
            name: form.logo[0]?.filename,
            file: form.logo[0]?.file,
        },
        atmospheric_image: {
            name: form.atmospheric_image[0]?.filename,
            file: form.atmospheric_image[0]?.file,
        },
    })).post(route('place-job-offer.store'), {
        onError: (errors) => {
            formErrors.value = errors;
            errors.errors = mapErrors(errors, (error) => [error]);
        },
        onSuccess: (errors) => {
            hasBeenSuccessful.value = true;
            setTimeout(() => (document.documentElement.scrollTop = 0), 100);
        },
    });
};

const logoPreview = computed(() =>
    form.logo && form.logo.length && form.logo[0]?.file instanceof File ? URL.createObjectURL(form.logo[0]?.file) : null,
);
const atmosphericImagePreview = computed(() =>
    form.atmospheric_image && form.atmospheric_image.length && form.atmospheric_image[0]?.file instanceof File
        ? URL.createObjectURL(form.atmospheric_image[0]?.file)
        : null,
);
</script>

<template>
    <PublicLayout title='FLEXABLE | Job offers'>
        <template v-if='!hasBeenSuccessful'>
            <div class='sticky -top-35 z-30 md:-top-30 md:min-h-80 xl:min-h-110 xl:-top-42'>
                <div
                    :class="y > 290 ? 'md:py-3' : 'md:py-9'"
                    class='z-40 w-full bg-gradient-to-r pt-9 pb-4 transition-all from-pink to-orange'
                >
                    <div class='container flex flex-col gap-6 xl:h-86'>
                        <Transition mode='out-in' name='fade'>
                            <div v-if='tabIndex === 0 && !kvkFilled' class='flex flex-col gap-6 grow'>
                                <div>
                                    <h1 class='max-w-260 text-xl font-medium uppercase md:text-3xl xl:text-7xl h-9 sm:h-auto'>
                                        <span class='font-black'>Leuk </span><span>dat je een </span><span
                                        class='font-black'>vacature </span><span>wilt plaatsen! </span>
                                    </h1>
                                </div>
                                <div class='max-w-screen-md text-sm font-semibold min-h-22 md:text-base'>
                                    <span>
                                        {{
                                            $t(
                                                'How nice to have you here! Enter your Chamber of Commerce number or company name below so that we can retrieve your company details and so that you can quickly post your first vacancy.',
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div v-else-if='tabIndex === 0' class='flex flex-col gap-6 grow'>
                                <div>
                                    <h1 class='max-w-screen-sm text-xl font-black uppercase md:text-3xl xl:text-7xl  sm:h-auto'>
                                        {{ $t('Company details') }}
                                    </h1>
                                </div>
                                <div class='max-w-screen-md text-sm font-semibold min-h-22 md:text-base'>
                                    <span>
                                        {{
                                            $t(
                                                'Check your company details here and complete your employer page. You only have to do this once and then all your vacancies will be neatly in one place. Handy huh?!',
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>

                            <div v-else-if='tabIndex === 1' class='flex flex-col gap-6 grow'>
                                <div>
                                    <h1 class='text-xl font-black uppercase max-w-80 md:text-3xl xl:text-7xl  sm:h-auto'>
                                        {{ $t('Choose plan') }}
                                    </h1>
                                </div>
                                <div class='max-w-screen-md text-sm font-semibold min-h-22 md:text-base'>
                                    <span>
                                       Jouw vacature ook op Flex@ble? Kies hieronder jouw gewenste pakket en plaats gemakkelijk en snel jouw vacature online!

                                    </span>
                                </div>
                            </div>

                            <div v-else-if='tabIndex === 2' class='flex flex-col gap-6 grow'>
                                <div>
                                    <h1 class='max-w-screen-sm text-xl font-black uppercase md:text-3xl xl:text-7xl  sm:h-auto'>
                                        {{ $t('Job offer description') }}
                                    </h1>
                                </div>
                                <div class='max-w-screen-md text-sm font-semibold min-h-22 md:text-base'>
                                    <span>
                                       Beschrijf hier zo duidelijk mogelijk de functie(vereisten), werkzaamheden, gewenste persoonlijke eigenschappen en vergoeding(en).
                                    </span>
                                </div>
                            </div>
                        </Transition>
                        <div class='flex gap-5 md:gap-20'>
                            <button
                                v-for='(tab, index) in tabs'
                                :key='index'
                                class='flex items-center gap-7 text-left min-h-15'
                                @click='slide(index)'
                            >
                                <div
                                    :class="
                                        tab.has_errors
                                            ? 'text-red'
                                            : index === tabIndex
                                            ? 'text-white'
                                            : 'text-white opacity-20'
                                    "
                                    class='text-2xl font-black transition-all duration-500 ease-in-out md:text-5xl'
                                >
                                    {{ index + 1 }}
                                </div>
                                <div>
                                    <h2
                                        :class="index === tabIndex ? 'text-white' : 'hidden lg:flex opacity-20'"
                                        class='text-xs sm:text-sm font-semibold uppercase transition-all duration-500 ease-in-out max-w-70 md:text-2xl'
                                    >
                                        {{ tab.step_title }}
                                    </h2>

                                    <span
                                        v-if='tab.has_errors'
                                        class='hidden text-sm font-semibold text-blue md:flex lg:text-lg'
                                    >{{ $t('Not all details filled in') }}</span
                                    >
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class='relative overflow-hidden bg-white text-blue -top-30 pt-30'>
                <Transition :name='transition'>
                    <div v-if='tabIndex === 0'>
                        <div class='py-15 md:text-left'>
                            <div class='container relative'>
                                <Transition mode='out-in' name='fade'>
                                    <div v-if='kvkFilled'>
                                        <h1 class='text-2xl font-black uppercase sm:text-4xl lg:text-6xl xl:text-7xl'>
                                            {{ $t('Company details') }}
                                        </h1>
                                        <p class='mt-2 text-lg'>
                                            {{
                                                $t(
                                                    'Check your company details here and complete your employer page. You only have to do this once and then all your vacancies will be neatly in one place. Handy huh?!',
                                                )
                                            }}
                                        </p>
                                        <FormKit :actions='false' type='form' @submit='submitHandler($event, 1)'>
                                            <div class='mx-auto mt-10 max-w-screen-lg'>
                                                <div class='grid grid-cols-6 gap-5'>
                                                    <div class='col-span-6'>
                                                        <h2 class='mb-6 text-lg md:text-4xl font-black uppercase'>
                                                            {{ $t('General') }}
                                                        </h2>
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.commercial_name'
                                                            :errors='formErrors?.errors?.company?.commercial_name'
                                                            :label="$t('Company name') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='commercial_name'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.kvk_number'
                                                            :errors='formErrors?.errors?.company?.kvk_number'
                                                            :label="$t('Kvk number') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='kvk_number'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.address'
                                                            :errors='formErrors?.errors?.company?.address'
                                                            :label="$t('Street name + House number') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='address'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>
                                                    <div class='col-span-6 md:col-span-3'>
                                                        <FormKit
                                                            v-model='form.company.postal_code'
                                                            :errors='formErrors?.errors?.company?.postal_code'
                                                            :label="$t('Postalcode') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='postal_code'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>

                                                    <div class='col-span-6 md:col-span-3'>
                                                        <FormKit
                                                            v-model='form.company.city'
                                                            :errors='formErrors?.errors?.company?.city'
                                                            :label="$t('Place name') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='city'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                            {{ $t('Company description') }}
                                                        </h2>
                                                    </div>

                                                    <div class='relative col-span-6 flex'>
                                                        <FormKit
                                                            v-model='form.company.description'
                                                            :errors='formErrors?.errors?.company?.description'
                                                            :label="'Vertel hier meer over je bedrijf' + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='description'
                                                            type='textarea'
                                                            validation='required'
                                                        />
                                                    </div>

                                                    <div class='col-span-6'>
                                                        <div class='mb-2'>
                                                            {{ $t('Company logo') + '*' }}
                                                        </div>
                                                        <Filepond
                                                            v-model='form.logo'
                                                            :inner-label="$t('Press to upload an image')"
                                                            color='pink'
                                                        ></Filepond>

                                                        <ul
                                                            v-if='logoPreviewError'
                                                            class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                        >
                                                            <li
                                                                class='mb-1 flex items-center gap-2 leading-tight'
                                                                data-message-type='error'
                                                            >
                                                                Logo is verplicht
                                                            </li>
                                                        </ul>

                                                        <div
                                                            v-if='logoPreview'
                                                            class='flex flex-col items-center justify-center gap-4 rounded-lg bg-opacity-20 p-6 text-white bg-blue-medium md:flex-row md:justify-between'
                                                        >
                                                            <div class='flex flex-col items-center gap-4 md:flex-row'>
                                                                <img
                                                                    :src='logoPreview'
                                                                    alt='Atmospheric image'
                                                                    class='mx-auto rounded-full object-cover h-30 w-30 md:mr-10'
                                                                />

                                                                <div>
                                                                    <h1
                                                                        class='max-w-screen-sm text-4xl font-black uppercase text-blue md:text-3xl xl:text-6xl text-center'
                                                                    >
                                                                        {{ form.company.commercial_name }}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                            <div class='flex items-center justify-center'>
                                                                <div
                                                                    class='rounded px-5 py-2 uppercase bg-blue min-w-42 text-center'>
                                                                    {{ $t('Logo preview') }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul
                                                            v-if='formErrors?.errors?.logo?.file'
                                                            class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                        >
                                                            <li
                                                                class='mb-1 flex items-center gap-2 leading-tight'
                                                                data-message-type='error'
                                                            >
                                                                {{ formErrors?.errors?.logo?.file[0] }}
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class='col-span-6'>
                                                        <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                            Contactpersoon bedrijf
                                                        </h2>
                                                    </div>

                                                    <div class='col-span-6 md:col-span-3'>
                                                        <FormKit
                                                            v-model='form.company.first_name'
                                                            :errors='formErrors?.errors?.company?.first_name'
                                                            :label="$t('First name') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='first_name'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>

                                                    <div class='col-span-6 md:col-span-3'>
                                                        <FormKit
                                                            v-model='form.company.last_name'
                                                            :errors='formErrors?.errors?.company?.last_name'
                                                            :label="$t('Last name') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='last_name'
                                                            type='text'
                                                            validation='required'
                                                        />
                                                    </div>

                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.email'
                                                            :errors='formErrors?.errors?.company?.email'
                                                            :label="$t('Email') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='email'
                                                            type='text'
                                                            validation='required|email'
                                                        />
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.function'
                                                            :errors='formErrors?.errors?.company?.function'
                                                            :label="$t('Function title ( Optional )')"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='function'
                                                            type='text'
                                                        />
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.tel'
                                                            :errors='formErrors?.errors?.company?.tel'
                                                            :label="$t('Phone') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='tel'
                                                            type='text'
                                                            validation='required|length:10'
                                                        />
                                                    </div>

                                                    <div class='col-span-6'>
                                                        <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                            Factuurgegevens
                                                        </h2>
                                                    </div>
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.company.invoice_address'
                                                            :errors='formErrors?.errors?.company?.invoice_address'
                                                            :label="$t('Facturation emailaddress') + '*'"
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='invoice_address'
                                                            type='text'
                                                            validation='required|email'
                                                        />
                                                    </div>
                                                    <!--                                                    <div class='col-span-6'>-->
                                                    <!--                                                        <FormKit-->
                                                    <!--                                                            v-model='form.company.vat_number'-->
                                                    <!--                                                            :errors='formErrors?.errors?.company?.vat_number'-->
                                                    <!--                                                            :label="'Btw-nummer' + '*'"-->
                                                    <!--                                                            :label-class="{-->
                                                    <!--                                                                'text-blue': false,-->
                                                    <!--                                                            }"-->
                                                    <!--                                                            name='vat_number'-->
                                                    <!--                                                            type='text'-->
                                                    <!--                                                            validation='required'-->
                                                    <!--                                                        />-->
                                                    <!--                                                    </div>-->
                                                    <div class='col-span-6'>
                                                        <FormKit
                                                            v-model='form.accepted'
                                                            :errors='formErrors?.errors?.accepted'
                                                            :label="
                                                                $t(
                                                                    'I agree with the Terms and conditions and Privacy policy of Flexable.'
                                                                )
                                                            "
                                                            :label-class="{
                                                                'text-blue': false,
                                                            }"
                                                            name='terms'
                                                            type='checkbox'
                                                            validation='accepted'
                                                        />
                                                    </div>
                                                </div>

                                                <p class='text-sm font-medium'>
                                                    *verplicht veld
                                                </p>

                                                <Button
                                                    class='mt-6 w-full'
                                                    pinkTransparent
                                                    text='Volgende'
                                                    type='submit'
                                                >
                                                </Button>
                                            </div>
                                        </FormKit>
                                    </div>
                                    <div v-else>
                                        <div class='flex flex-col justify-center gap-5'>
                                            <div class='flex justify-center'>
                                                <Combobox
                                                    v-model='selectedCompany'
                                                    as='div'
                                                    class='w-full md:min-w-160 md:w-auto'
                                                >
                                                    <ComboboxLabel
                                                        class='block text-sm font-medium leading-6 text-gray-900'
                                                    >{{
                                                            $t(
                                                                'Search for your company by Chamber of Commerce number or name',
                                                            )
                                                        }}
                                                    </ComboboxLabel>
                                                    <div class='relative mt-2'>
                                                        <ComboboxInput
                                                            :display-value='(company) => company?.trade_name'
                                                            autoComplete='off'
                                                            class='w-full rounded-md border bg-white py-2 pr-10 pl-3 text-gray-900 shadow-sm ring-inset ring-gray-300 focus:ring-blue focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                                            @change='searchCompaniesQuery = $event.target.value'
                                                            @input='debouncedGetKvkCompanies'
                                                        />
                                                        <ComboboxButton
                                                            class='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
                                                        >
                                                            <MagnifyingGlassIcon
                                                                class='h-5 w-5 text-gray-400'
                                                            ></MagnifyingGlassIcon>
                                                        </ComboboxButton>

                                                        <ComboboxOptions
                                                            v-if='companiesList.length > 0'
                                                            class='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                                                        >
                                                            <ComboboxOption
                                                                v-for='company in companiesList'
                                                                :key='company.kvk_number'
                                                                v-slot='{ active, selected }'
                                                                :value='company'
                                                                as='template'
                                                            >
                                                                <li
                                                                    :class="[
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9 m-2 rounded',
                                                                        active
                                                                            ? 'bg-blue text-white'
                                                                            : 'text-blue bg-white',
                                                                    ]"
                                                                >
                                                                    <span
                                                                        :class="[
                                                                            'block truncate',
                                                                            selected && 'font-black',
                                                                        ]"
                                                                    >
                                                                        {{ company.trade_name }} -
                                                                        {{ company.kvk_number }}
                                                                    </span>
                                                                </li>
                                                            </ComboboxOption>
                                                        </ComboboxOptions>
                                                    </div>
                                                </Combobox>
                                            </div>

                                            <Button
                                                v-if='selectedCompany'
                                                class='w-full md:min-w-160 md:w-auto'
                                                @click.prevent='fillDetailsFromKvk'
                                            >GA DOOR MET:
                                                {{ selectedCompany.trade_name }}
                                            </Button>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>


                        <div v-if='!kvkFilled' class='bg-white lg:pt-20 pb-30 text-blue md:text-left'>

                            <div class='container relative flex flex-col gap-10'>
                                <span>
                                    {{
                                        $t(
                                            'Do you have an open vacancy within your company, but are you having trouble finding the right candidate? Easily post your vacancy on Flex@ble and find the right candidate for your vacancy in no time!',
                                        )
                                    }}
                                </span>

                                <template v-for='block in page?.blocks' :key='block.id'>
                                    <template v-if="block.component_name === 'job-offer-usps'">
                                        <h3 class='max-w-screen-lg text-3xl font-black uppercase text-pink'
                                            v-html='block.content.title'>
                                        </h3>
                                        <div class='grid grid-cols-1 gap-8 md:grid-cols-2'>
                                            <div v-for='usp in block.content.usps' class='flex gap-4 md:ml-12'>
                                                <Icon :icon='Checkmark' class='h-6 w-6' />
                                                <div v-html='usp.title'>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                            </div>
                        </div>

                    </div>

                    <div v-else-if='tabIndex === 1'>
                        <div class='mb-5 py-15 md:text-left'>
                            <div class='container relative'>
                                <div class='mt-10 flex flex-col items-center'>
                                    <h2 class='mt-5 text-3xl font-black uppercase lg:text-4xl xl:text-5xl'>
                                        {{ $t('Make your choice') }}
                                    </h2>
                                    <div
                                        class='mt-10 grid w-full grid-cols-1 gap-8 text-white md:grid-cols-2 xl:grid-cols-3'
                                    >
                                        <div
                                            v-for='(plan, index) in plans'
                                            :key='index'
                                            :class="
                                                form.company.plan_id === plan.id
                                                    ? 'bg-gradient-to-br from-pink to-orange'
                                                    : 'bg-blue-medium'
                                            "
                                            class='flex flex-col items-center gap-5 p-8 transition rounded-tl-[4rem]'
                                        >
                                            <h2 class='text-8xl font-black'>
                                                {{ index + 1 }}
                                            </h2>

                                            <h2 class='text-6xl font-bold uppercase'>
                                                {{ plan.title }}
                                            </h2>

                                            <div class='my-4 flex w-full grow flex-col gap-4'>
                                                <div v-for='content in plan.contents' class='flex gap-4'>
                                                    <Icon :icon='Checkmark' class='h-6 w-6' />
                                                    <p>
                                                        {{ content.title }}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class='flex flex-col items-center gap-1'>
                                                <h3 class='text-6xl font-bold'>
                                                    <!--                                                    {{ $n(plan.price / 100, 'currency', 'nl') }} Not working for some reason-->
                                                    € {{ (plan.price / 100).toFixed(2) }}
                                                </h3>
                                                <span class='text-xl font-bold'>
                                                    {{ $t('Per month') }}
                                                </span>
                                            </div>

                                            <Button
                                                :blue='form.company.plan_id === plan.id'
                                                :class="form.company.plan_id === plan.id ? 'bg-opacity-75' : ''"
                                                :text="$t('Choose')"
                                                class='w-full'
                                                @click='form.company.plan_id = plan.id'
                                            >
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='bg-white pb-20 text-blue md:text-left'>
                            <div class='container relative mx-auto flex max-w-screen-xl flex-col gap-5'>
                                <div id='additions' class='absolute -top-30'></div>
                                <!--                                <span class='text-lg'>-->
                                <!--                                    {{-->
                                <!--                                        $t(-->
                                <!--                                            'Your vacancy also on Flex@ble? Choose your desired package below and post your vacancy online quickly and easily! As indication:',-->
                                <!--                                        )-->
                                <!--                                    }}-->
                                <!--                                </span>-->
                                <div class='text-center'>
                                    <h3 class='text-4xl font-black uppercase text-pink mt-5'>
                                        {{ $t('Add ons') }}
                                    </h3>
                                </div>
                                <fieldset class='mt-5'>
                                    <div class='space-y-10'>
                                        <div v-for='extra in plan_extras' class='relative flex items-start'>
                                            <div class='flex h-6 items-center'>
                                                <input
                                                    :id="'extra-' + extra.id"
                                                    v-model='form.plan_extras'
                                                    :value='extra.id'
                                                    aria-describedby='comments-description'
                                                    class='h-4 w-4 rounded border-gray-300 form-checkbox text-pink focus:ring-pink'
                                                    name='extras'
                                                    type='checkbox'
                                                />
                                            </div>
                                            <div class='ml-3 text-lg'>
                                                <label :for="'extra-' + extra.id" class='text-blue'>{{
                                                        extra.description
                                                    }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <div class='flex sm:gap-6 flex-col sm:flex-row'>
                                    <Button
                                        :text="$t('Previous')"
                                        class='mt-6 w-full bg-transparent !text-pink !border-2 !border-pink hover:bg-pink hover:!text-white'

                                        @click.prevent='nextStep(0)'
                                    >
                                    </Button>

                                    <Button
                                        :text="$t('Next')"
                                        class='mt-6 w-full'
                                        pinkTransparent
                                        @click.prevent='form.company.plan_id ? nextStep(2) : null'
                                    >
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else-if='tabIndex === 2'>
                        <div class='py-15 md:text-left'>
                            <div class='container relative'>
                                <h1 class='text-2xl font-black uppercase sm:text-4xl lg:text-6xl xl:text-7xl'>

                                    {{ $t('Job offer description') }}
                                </h1>
                                <!--                                <p class='mt-2 text-lg'>-->
                                <!--                                    {{-->
                                <!--                                        $t(-->
                                <!--                                            'Check your company details here and complete your employer page. You only have to do this once and then all your vacancies will be neatly in one place. Handy huh?!',-->
                                <!--                                        )-->
                                <!--                                    }}-->
                                <!--                                </p>-->
                                <FormKit :actions='false' type='form' @submit='sendForm'>
                                    <div class='mx-auto mt-10 max-w-screen-lg'>
                                        <div class='grid grid-cols-6 gap-5'>
                                            <div class='col-span-6'>
                                                <h2 class='mb-6 text-lg md:text-4xl font-black uppercase'>
                                                    {{ $t('General') }}
                                                </h2>
                                            </div>
                                            <div class='col-span-6'>
                                                <FormKit
                                                    v-model='form.job_offer.function'
                                                    :errors='formErrors?.errors?.job_offer?.function'
                                                    :label="$t('Function') + '*'"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    name='job_offer_function'
                                                    type='text'
                                                    validation='required'
                                                />
                                            </div>
                                            <div class='col-span-6'>
                                                <FormKit
                                                    v-model='form.job_offer.department'
                                                    :errors='formErrors?.errors?.job_offer?.department'
                                                    :label="$t('Department') + '*'"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    name='department'
                                                    type='text'
                                                    validation='required'
                                                />
                                            </div>
                                            <div class='col-span-6'>
                                                <FormKit
                                                    v-model='form.job_offer.hours'
                                                    :errors='formErrors?.errors?.job_offer?.hours'
                                                    :label="$t('Amount of hours per week') + '*'"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    min='0' name='hours'
                                                    type='number'
                                                    validation='required'
                                                />
                                            </div>
                                            <div class='col-span-6'>

                                                <FormKit
                                                    v-model='form.job_offer.start_type'
                                                    :label="$t('Start date') + '*'"
                                                    :options="{
                                                date: 'Datum',
                                                immediately: 'Per direct',
                                                consultation: 'In overleg',
                                              }"
                                                    name='start_type'
                                                    type='radio'
                                                />
                                            </div>

                                            <div v-if="form.job_offer.start_type === 'date'"
                                                 class='col-span-6 md:col-span-3'>
                                                <FormKit
                                                    v-model='form.job_offer.start_date'
                                                    :errors='formErrors?.errors?.job_offer?.start_date'
                                                    :label="$t('Start date') + '*'"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    name='start_date'
                                                    type='date'
                                                    validation='required'
                                                />
                                            </div>

                                            <div class='col-span-6 md:col-span-3'>
                                                <FormKit
                                                    v-model='form.job_offer.end_date'
                                                    :errors='formErrors?.errors?.job_offer?.end_date'
                                                    :label="$t('End date (if necessary)')"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    name='end_date'
                                                    type='date'
                                                />
                                            </div>
                                            <div class='col-span-6'>
                                                <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                    Gewenst opleidingsniveau
                                                </h2>
                                            </div>

                                            <div class='col-span-6 mb-5'>
                                                <FormKit
                                                    v-model='form.job_offer.education'
                                                    :errors='formErrors?.errors?.job_offer?.education'
                                                    :options="{
                                                        secondary_school: $t('Secondary school'),
                                                        secondary_vocational_education: $t(
                                                            'Secondary vocational education'
                                                        ),
                                                        higher_vocational_education: $t('Higher vocational education'),
                                                        academic_education: $t('Academic education'),
                                                        not_applicable: t('not applicable')
                                                    }"
                                                    :validation-label="$t('Education')"
                                                    type='radio'
                                                    validation='required'
                                                />
                                            </div>

                                            <div class='col-span-6'>
                                                <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                    {{ $t('Employment') }}
                                                </h2>
                                            </div>

                                            <div class='col-span-6 mb-5'>
                                                <FormKit
                                                    v-model='form.job_offer.employment'
                                                    :errors='formErrors?.errors?.job_offer?.employment'
                                                    :options="{
                                                        employed: $t('Employed'),
                                                        temp_worker: $t('Temporary worker (employed by Flex@ble)'),
                                                    }"
                                                    :validation-label="$t('Employment')"
                                                    type='radio'
                                                    validation='required'
                                                />
                                            </div>

                                            <div class='col-span-6'>
                                                <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                    {{ $t('Job offer description') }}
                                                </h2>
                                            </div>

                                            <div class='col-span-6'>
                                                <FormKit
                                                    v-model='form.job_offer.description'
                                                    :errors='formErrors?.errors?.job_offer?.description'
                                                    :label="$t('About the job offer') + '*'"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    name='job_offer_description'
                                                    type='textarea'
                                                    validation='required'
                                                />
                                            </div>
                                            <div class='col-span-6'>
                                                <div class='mb-2'>
                                                    {{ $t('Upload an atmospheric image') + '* (leuke foto van het team, een medewerker of de winkel)'
                                                    }}
                                                </div>
                                                <Filepond
                                                    v-model='form.atmospheric_image'
                                                    :inner-label="$t('Press to upload an image')"
                                                    color='pink'
                                                ></Filepond>


                                                <ul
                                                    v-if='atmosphericImagePreviewError'
                                                    class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                >
                                                    <li
                                                        class='mb-1 flex items-center gap-2 leading-tight'
                                                        data-message-type='error'
                                                    >
                                                        Sfeerbeeld is verplicht
                                                    </li>
                                                </ul>

                                                <div
                                                    v-if='atmosphericImagePreview'
                                                    class='rounded-lg bg-opacity-20 p-6 text-white bg-blue-medium'
                                                >
                                                    <img
                                                        :src='atmosphericImagePreview'
                                                        alt='Atmospheric image'
                                                        class='w-full rounded object-cover h-45 md:h-80'
                                                    />

                                                    <div class='flex items-center justify-center pt-6'>
                                                        <div class='rounded px-5 py-2 uppercase bg-blue'>
                                                            {{ $t('Atmospheric image preview') }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul
                                                    v-if='formErrors?.errors?.atmospheric_image?.file'
                                                    class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                >
                                                    <li
                                                        class='mb-1 flex items-center gap-2 leading-tight'
                                                        data-message-type='error'
                                                    >
                                                        {{ formErrors?.errors?.atmospheric_image?.file[0] }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class='col-span-6'>
                                                <div class='flex flex-col gap-2'>
                                                    <div>
                                                        {{ 'Omschrijving van de werkzaamheden' + '*' }}
                                                    </div>
                                                    <div
                                                        v-if='form.job_offer.main_activities.length'
                                                        class='mb-3 flex flex-col gap-4'
                                                    >
                                                        <div
                                                            v-for='(main_activity, index) in form.job_offer
                                                                .main_activities'
                                                            class='flex items-center justify-between rounded-lg bg-opacity-20 px-5 py-3 bg-blue-medium'
                                                        >
                                                            <div class='flex items-center gap-2'>
                                                                <CheckIcon class='h-8 w-8'></CheckIcon>
                                                                <span>
                                                                    {{ main_activity }}
                                                                </span>
                                                            </div>
                                                            <button
                                                                class='flex h-10 w-10 items-center justify-center rounded-full bg-pink hover:bg-opacity-75'
                                                                @click.prevent='
                                                                    form.job_offer.main_activities.splice(index, 1)
                                                                '
                                                            >
                                                                <XMarkIcon class='h-8 w-8 text-white'></XMarkIcon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <FormKit
                                                        v-model='mainActivity'
                                                        :label-class="{
                                                            'text-blue': false,
                                                        }"
                                                        help='Je kunt hier meerdere velden toevoegen. Deze worden getoond als bullet points in de vacaturepagina.'
                                                        type='text'
                                                    />
                                                    <Button
                                                        class='w-full'
                                                        @click.prevent="
                                                            mainActivity.length > 2
                                                                ? (form.job_offer.main_activities.push(mainActivity),
                                                                  (mainActivity = ''))
                                                                : null
                                                        "
                                                    >
                                                        Taak toevoegen
                                                    </Button>
                                                    <ul
                                                        v-if='formErrors?.errors?.job_offer?.main_activities'
                                                        class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                    >
                                                        <li
                                                            class='mb-1 flex items-center gap-2 leading-tight'
                                                            data-message-type='error'
                                                        >
                                                            {{ formErrors?.errors?.job_offer?.main_activities[0] }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class='col-span-6'>
                                                <div class='flex flex-col gap-2'>
                                                    <div>
                                                        Over welke persoonlijke eigenschappen moet de kandidaat
                                                        beschikken?*
                                                    </div>
                                                    <div
                                                        v-if='form.job_offer.who_you_are.length'
                                                        class='mb-3 flex flex-col gap-4'
                                                    >
                                                        <div
                                                            v-for='(about, index) in form.job_offer.who_you_are'
                                                            class='flex items-center justify-between rounded-lg bg-opacity-20 px-5 py-3 bg-blue-medium'
                                                        >
                                                            <div class='flex items-center gap-2'>
                                                                <CheckIcon class='h-8 w-8'></CheckIcon>
                                                                <span>
                                                                    {{ about }}
                                                                </span>
                                                            </div>
                                                            <button
                                                                class='flex h-10 w-10 items-center justify-center rounded-full bg-pink hover:bg-opacity-75'
                                                                @click.prevent='
                                                                    form.job_offer.who_you_are.splice(index, 1)
                                                                '
                                                            >
                                                                <XMarkIcon class='h-8 w-8 text-white'></XMarkIcon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <FormKit
                                                        v-model='whoYouAre'
                                                        :label-class="{
                                                            'text-blue': false,
                                                        }"
                                                        help='Je kunt hier meerdere velden toevoegen. Deze worden getoond als bullet points in de vacaturepagina.'
                                                        type='text'
                                                    />
                                                    <Button
                                                        :class="whoYouAre.length > 2 ? '' : '!bg-blue-medium'"
                                                        class='w-full'
                                                        @click.prevent="
                                                            whoYouAre.length > 2
                                                                ? (form.job_offer.who_you_are.push(whoYouAre),
                                                                  (whoYouAre = ''))
                                                                : null
                                                        "
                                                    >
                                                        {{ $t('Add Desired Property') }}
                                                    </Button>
                                                    <ul
                                                        v-if='formErrors?.errors?.job_offer?.who_you_are'
                                                        class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                    >
                                                        <li
                                                            class='mb-1 flex items-center gap-2 leading-tight'
                                                            data-message-type='error'
                                                        >
                                                            {{ formErrors?.errors?.job_offer?.who_you_are[0] }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class='col-span-6'>
                                                <div class='flex flex-col gap-2'>
                                                    <div>
                                                        Dit bieden wij de kandidaat*
                                                    </div>
                                                    <div
                                                        v-if='form.job_offer.what_you_get.length'
                                                        class='mb-3 flex flex-col gap-4'
                                                    >
                                                        <div
                                                            v-for='(what, index) in form.job_offer.what_you_get'
                                                            class='flex items-center justify-between rounded-lg bg-opacity-20 px-5 py-3 bg-blue-medium'
                                                        >
                                                            <div class='flex items-center gap-2'>
                                                                <CheckIcon class='h-8 w-8'></CheckIcon>
                                                                <span>
                                                                    {{ what }}
                                                                </span>
                                                            </div>
                                                            <button
                                                                class='flex h-10 w-10 items-center justify-center rounded-full bg-pink hover:bg-opacity-75'
                                                                @click.prevent='
                                                                    form.job_offer.what_you_get.splice(index, 1)
                                                                '
                                                            >
                                                                <XMarkIcon class='h-8 w-8 text-white'></XMarkIcon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <FormKit
                                                        v-model='whatYouGet'
                                                        :label-class="{
                                                            'text-blue': false,
                                                        }"
                                                        help='Je kunt hier meerdere velden toevoegen. Deze worden getoond als bullet points in de vacaturepagina.'
                                                        type='text'
                                                    />
                                                    <Button
                                                        :class="whatYouGet.length > 2 ? '' : '!bg-blue-medium'"
                                                        class='w-full'
                                                        @click.prevent="
                                                            whatYouGet.length > 2
                                                                ? (form.job_offer.what_you_get.push(whatYouGet),
                                                                  (whatYouGet = ''))
                                                                : null
                                                        "
                                                    >
                                                        {{ $t('Add USP') }}
                                                    </Button>
                                                    <ul
                                                        v-if='formErrors?.errors?.job_offer?.what_you_get'
                                                        class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                    >
                                                        <li
                                                            class='mb-1 flex items-center gap-2 leading-tight'
                                                            data-message-type='error'
                                                        >
                                                            {{ formErrors?.errors?.job_offer?.what_you_get[0] }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class='col-span-6'>
                                                <h2 class='my-6 text-lg md:text-4xl font-black uppercase'>
                                                    {{ $t('Salary and other compensations') }}
                                                </h2>
                                            </div>

                                            <div class='col-span-6'>
                                                <FormKit
                                                    v-model='form.job_offer.salary'
                                                    :errors='formErrors?.errors?.job_offer?.salary'
                                                    :label="$t('Salary')"
                                                    :label-class="{
                                                        'text-blue': false,
                                                    }"
                                                    name='salary'
                                                    type='text'
                                                />
                                            </div>
                                            <div class='col-span-6'>
                                                <div class='flex flex-col gap-2'>
                                                    <div>
                                                        {{ 'Secundaire arbeidsvoorwaarden' + ' (denk aan korting op je boodschappen, reiskosten vergoeding, pensioen etc)'
                                                        }}
                                                    </div>
                                                    <div
                                                        v-if='form.job_offer.compensations.length'
                                                        class='mb-3 flex flex-col gap-4'
                                                    >
                                                        <div
                                                            v-for='(compensation, index) in form.job_offer
                                                                .compensations'
                                                            class='flex items-center justify-between rounded-lg bg-opacity-20 px-5 py-3 bg-blue-medium'
                                                        >
                                                            <div class='flex items-center gap-2'>
                                                                <CheckIcon class='h-8 w-8'></CheckIcon>
                                                                <span>
                                                                    {{ compensation }}
                                                                </span>
                                                            </div>
                                                            <button
                                                                class='flex h-10 w-10 items-center justify-center rounded-full bg-pink hover:bg-opacity-75'
                                                                @click.prevent='
                                                                    form.job_offer.compensations.splice(index, 1)
                                                                '
                                                            >
                                                                <XMarkIcon class='h-8 w-8 text-white'></XMarkIcon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <FormKit
                                                        v-model='compensation'
                                                        :label-class="{
                                                            'text-blue': false,
                                                        }"
                                                        help='Je kunt hier meerdere velden toevoegen. Deze worden getoond als bullet points in de vacaturepagina.'
                                                        type='text'
                                                    />
                                                    <Button
                                                        :class="compensation.length > 2 ? '' : '!bg-blue-medium'"
                                                        class='w-full'
                                                        @click.prevent="
                                                            compensation.length > 2
                                                                ? (form.job_offer.compensations.push(compensation),
                                                                  (compensation = ''))
                                                                : null
                                                        "
                                                    >
                                                        {{ $t('Add other fees') }}
                                                    </Button>
                                                    <ul
                                                        v-if='formErrors?.errors?.job_offer?.compensations'
                                                        class='mt-3 mb-3 list-none p-0 text-xs font-semi text-pink'
                                                    >
                                                        <li
                                                            class='mb-1 flex items-center gap-2 leading-tight'
                                                            data-message-type='error'
                                                        >
                                                            {{ formErrors?.errors?.job_offer?.compensations[0] }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class='col-span-6'>

                                                <p class='text-sm font-medium'>
                                                    *verplicht veld
                                                </p>
                                                <div class='flex sm:gap-6 flex-col sm:flex-row'>
                                                    <Button
                                                        :text="$t('Previous')"
                                                        class='mt-6 w-full bg-transparent !text-pink !border-2 !border-pink hover:bg-pink hover:!text-white'

                                                        @click.prevent='nextStep(1)'
                                                    >
                                                    </Button>
                                                    <Button
                                                        :processing='form.processing'
                                                        :recentlySuccessful='form.recentlySuccessful'
                                                        :text="$t('Send')"
                                                        class='mt-6 w-full'
                                                        type='submit'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FormKit>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </template>

        <template v-else>
            <div class='relative bg-gradient-to-r pt-15 from-pink to-orange md:min-h-10 md:py-15'>
                <div class='container'>
                    <div class='mt-20 pb-20 max-w-screen-md'>
                        <h2 class='text-4xl font-black uppercase xl:text-6xl pb-10 md:pb-0'>
                            {{ $t('Thanks for posting your vacancy!') }}
                        </h2>
                    </div>
                </div>
            </div>
            <div class='relative bg-white py-20 text-blue md:min-h-10 md:py-20'>
                <div class='container'>
                    <div class='max-w-screen-md'>
                        <span>
                           De vacature zal worden gecontroleerd door onze support afdeling, waarna deze binnen 48 uur zal worden geplaatst op de website. Doorgaans wordt deze dezelfde dag al verwerkt
                        </span>
                        <br>
                        <span>
                          Ben je iets vergeten of wil je nog een wijziging aandbrengen? Neem dan contact op met support@flexable.nl
                        </span>
                    </div>

                    <div class='mx-auto mt-10 max-w-screen-md'>
                        <LinkButton :href="route('home')" class='w-full' pink text='Terug naar home'></LinkButton>
                    </div>
                </div>
            </div>
        </template>
    </PublicLayout>
</template>

<style>
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all 0.4s ease-out;
}

.slide-left-enter-from {
    opacity: 0;
    transform: translate3d(100vw, 0, 0);
}

.slide-left-leave-to {
    opacity: 0;
    transform: translate3d(-100vw, 0, 0);
    position: absolute;
    width: 100%;
}

.slide-right-enter-active,
.slide-right-leave-active {
    transition: all 0.4s ease-out;
}

.slide-right-enter-from {
    opacity: 0;
    transform: translate3d(-100vw, 0, 0);
}

.slide-right-leave-to {
    opacity: 0;
    transform: translate3d(100vw, 0, 0);
    position: absolute;
    width: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
